/*
*   MDcomponent - Form field (only for login)
*   11/2019
*   author: Jakub Blana
*   ===========================================
*/

import {MDCRipple} from '@material/ripple';
import {MDCFloatingLabel} from '@material/floating-label';
import {MDCTextField} from '@material/textfield';

var MDCTextItems, MDCLabelItems;

var MDC = {
    'text': {
        'items': function() {
            MDC.text.initialize();
            return MDCTextItems;
        },
        'refreshElement': function (element) {
            if (element.disabled && !element.root_.classList.contains('mdc-text-field--disabled')) {
                element.root_.classList.add('mdc-text-field--disabled');
            }
            else if (!element.disabled && element.root_.classList.contains('mdc-text-field--disabled')) {
                element.root_.classList.remove('mdc-text-field--disabled');
            }
            if (element.label_ !== null) {
                // if (element.value && !element.label_.root_.classList.contains('mdc-floating-label--float-above'))
                //     element.foundation_.adapter_.floatLabel(true);
                // else if (!element.value && element.label_.root_.classList.contains('mdc-floating-label--float-above'))
                //     element.foundation_.adapter_.floatLabel(false);

                element.layout();
                MDC.text.floatTextFieldLabel(element);
            }
            if(element.root_.classList.contains('PR-formEl--with-appendText')){
                var $textField = $(element.root_);
                var $textFieldAppendText = $textField.find('.mdc-text-field__appendText');
                var textFieldAppendTextWidth = $textFieldAppendText.outerWidth();
                element.root_.style.setProperty("--PR-formEl__appendText--width", textFieldAppendTextWidth + "px");
            }
        },
        'floatTextFieldLabel': function(textField) { // funkce pro fixaci labelu ve float pozici
            textField.foundation_.adapter_.floatLabel(true);
            textField.foundation_.adapter_.notchOutline(textField.label_.root_.clientWidth*.75);
        },
        'initialize': function() {
            MDCTextItems = [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
                var $textField = $(el);
                if ($textField.data('mdc-init')) {
                    MDC.text.refreshElement($textField.data('mdc-init'));
                    return $textField.data('mdc-init');
                }

                var textField = new MDCTextField(el);
                MDC.text.refreshElement(textField);

                var refreshForEventListener = function () {
                    MDC.text.refreshElement(textField);
                };

                el.querySelector('.mdc-text-field__input').addEventListener('focus', refreshForEventListener);
                el.querySelector('.mdc-text-field__input').addEventListener('blur', refreshForEventListener);

                $textField.data('mdc-init', textField);
                return textField;
            });
        }
    },
    'label': {
        'items': function() {
            MDC.label.initialize();
            return MDCLabelItems;
        },
        'refreshElement': function(element) {

        },
        'initialize': function() {

            MDCLabelItems = [].map.call(document.querySelectorAll('.mdc-floating-label'), function(el) {
                var $label = $(el);
                if ($label.data('mdc-init')) {
                    MDC.label.refreshElement($label.data('mdc-init'));
                    return $label.data('mdc-init');
                }

                var label = new MDCFloatingLabel(el);
                MDC.label.refreshElement(label);
                $label.data('mdc-init', label);
                return label;
            });
        }
    },
};

$(function () {

    if($('.mdc-button').length) {
        const btnRipples = [].map.call(document.querySelectorAll('.mdc-button'), function (el) {
            return new MDCRipple(el);
        });
    }

    window.setFormElState_login = function(){
        MDC.text.initialize();
    };

    window.setFormElState_login();

});

